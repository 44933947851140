



































































































































import { Component, Watch, Vue } from 'vue-property-decorator';
import Breadcrumb from '@/components/Breadcrumb.vue';
import VerticalAligned from '@/components/VerticalAligned.vue';
import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'
import RelatedProducts from '@/components/products/RelatedProducts.vue';
import CatalogService, { Product } from '@/services/catalog.service';
import { MetaInfo } from 'vue-meta';
import eventBus from '@/services/eventBus';
import AuthService, { Identity } from '@/services/auth.service';
import BasketService, { Basket } from '@/services/basket.service';
import { vueVimeoPlayer } from 'vue-vimeo-player'

@Component({
  metaInfo(this: ProductDetail):MetaInfo {
    return {
      title: this.getPageTitle(),
      meta: [
        { name: 'description', content: this.getPageDescription() },
        { name: 'keywords', content: this.getPageKeywords() },
      ]
    }
  },
  components: {
    Breadcrumb,
    VueperSlides, 
    VueperSlide,
    VerticalAligned,
    RelatedProducts,
    'vimeo-player': vueVimeoPlayer
  },
})
export default class ProductDetail extends Vue {

    getPageTitle() {
      return `${this.$data.title} | Pacific Standard`;
    }

    getPageDescription() {
      return this.$data.description;
    }

    getPageKeywords() {
        return this.$data.keywords;
    }

    data() {
        return {
            title: 'Nuestros productos',
            description: 'Pacific Standard es una marca especializada en equipo para soldar, contamos con una amplia variedad de productos para que encuentres todo lo que necesitas.',
            keywords: '',
            breadcrumbPath: [
                {
                    text: 'Inicio',
                    href: '/'
                },
                {
                    text: 'Productos',
                    href: '/productos'
                },
            ],
            identity: null,
            qty: 1,
            model: null,
            options: {
                controls: false,
                responsive: true,
                autoplay: true,
                background: true,
                autopause: false,
                muted: false,
                loop: true
            }
        };
    }

    @Watch('$route.params', { deep: true })
    onModelChange(newVal:any):void {
        this.updateModel();
    }

    created() {
        eventBus.$on('identity-updated', (identity:Identity) => this.$data.identity = identity);
        this.$data.identity = AuthService.getIdentity();
        this.updateModel();
    }

    addToMyBasket() {
        BasketService.addItem(this.$data.qty, this.$data.model);
    }

    updateModel() {
      let id = parseInt(this.$route.params?.id);
      CatalogService.getProduct(id).then((model:Product) => {
        this.$data.model = model;
        this.$data.breadcrumbPath = [
            {
                text: 'Inicio',
                href: '/'
            },
            {
                text: 'Productos',
                href: '/productos'
            },
            {
                text: model?.category?.name,
                href: `/productos/categoria/${model?.category?.id}/${model?.category?.slug}`
            },
            {
                text: model.name,
                active: true
            }
        ];
        this.$data.title = model.name;
        this.$data.description = `${model.sku} ${model.name}`;
        this.$data.keywords = model.tags.join(',');
        this.$data.keywords = `${this.$data.keywords}, ${model.sku}, ${model.name}`;
      });
    }

    getAssetPath(image:string):string {
        let images = require.context('../assets/', true, /\.(gif|jpg|png|svg)$/);
        return images(image);
    }
}
