import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Home from '../views/Home.vue'
import Products from '../views/Products.vue'
import ProductDetail from '../views/ProductDetail.vue'
import Blog from '../views/Blog.vue'
import PostDetail from '../views/PostDetail.vue'
import Contact from '../views/Contact.vue'
import Distributors from '../views/Distributors.vue'
import Auth from '../views/Auth.vue'
import Privacy from '../views/Privacy.vue'
import Terms from '../views/Terms.vue'
import MyAccount from '../views/MyAccount.vue'
import Brands from '../views/Brands.vue'
import AuthService, { Identity } from '@/services/auth.service';
import { GenericResponse } from '@/services/interceptor'
import MyQuotes from '../views/MyQuotes.vue'
import PasswordResetResponse from '../views/PasswordResetResponse.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/marcas',
    name: 'Marcas',
    component: Brands
  },
  {
    path: '/productos/marca/:brand_id/:slug',
    component: Products
  },
  {
    path: '/productos/categoria/:category_id/:slug',
    component: Products
  },
  {
    path: '/productos/buscar/:search',
    component: Products
  },
  {
    path: '/productos',
    component: Products
  },
  {
    path: '/producto/:id/:slug',
    name: 'ProductDetail',
    component: ProductDetail
  },
  {
    path: '/blog',
    name: 'Blog',
    component: Blog
  },
  {
    path: '/post/:id/:slug',
    name: 'PostDetail',
    component: PostDetail
  },
  {
    path: '/nosotros',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/contacto',
    name: 'Contact',
    component: Contact
  },
  {
    path: '/distribuidores',
    name: 'Distributors',
    component: Distributors
  },
  {
    path: '/auth',
    name: 'Auth',
    component: Auth
  },
  {
    path: '/password-reset/:success',
    name: 'PasswordResetResponse',
    component: PasswordResetResponse
  },
  {
    path: '/aviso-de-privacidad',
    component: Privacy
  },
  {
    path: '/terminos-y-condiciones',
    component: Terms
  },
  {
    path: '/mi-cuenta',
    name: 'MyAccount',
    component: MyAccount,
    beforeEnter: (to, from, next) =>  {
      AuthService.isLoggedIn().then((response:GenericResponse) => {
        response.success == true ? next() : next({ name: 'Login' });
      }, error => {
        next({ name: 'Login' })
      });
    }
  },
  {
    path: '/mis-cotizaciones',
    name: 'MyQuotes',
    component: MyQuotes,
    beforeEnter: (to, from, next) =>  {
      AuthService.isLoggedIn().then((response:GenericResponse) => {
        response.success == true ? next() : next({ name: 'Login' });
      }, error => {
        next({ name: 'Login' })
      });
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
