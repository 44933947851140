







































import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'
import CatalogService, { Category } from '@/services/catalog.service';

@Component({
  components: { VueperSlides, VueperSlide },
})
export default class Categories extends Vue {

    data() {
        return {
            slides: []
        }
    }

    created() {
        CatalogService.getFeaturedCategories().then((models:Category[]) => {
            this.$data.slides = models.filter( (model:Category) => model.featured == true );
        }).catch(error => {
            this.$data.slides = [];
        });
    }

    getAssetPath(image:string):string {
        let images = require.context('../../assets/', true, /\.(gif|jpg|png|svg)$/);
        return images(image);

    }
}
