
























































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Validate, Validations } from 'vuelidate-property-decorators';
import { required, email } from 'vuelidate/lib/validators'
import AuthService, { Identity } from '@/services/auth.service';
import eventBus from '@/services/eventBus';

@Component(
  {
    components: { }
  }
)
export default class Login extends Vue {

  // @Validate({required, email})
  // email = '';

  // @Validate({required})
  // password = '';

  data() {
      return {
        form: {
          email: null,
          password: null
        },
        loginError: null
      }
  }

  @Validations()
  validations() {
    return {
        form: {
          email: { email, required },
          password: { required }
        }
    };
  }

  onSubmit(event) {
    event.preventDefault()

    this!['$v'].$touch()
    if (!this!['$v'].$invalid) {
      AuthService.login(this.$data.form.email, this.$data.form.password).then((response:Identity) => {
        AuthService.setIdentity(response);
        this.$router.push('/mi-cuenta');
      }, error => {
        this.$data.loginError = true;
      });
    } else {
      console.log('error')
    }
  }

  toggleLogin() {
    eventBus.$emit('toggle-login', false);
  }
}
