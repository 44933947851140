


















































import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'
import VerticalAligned from '../VerticalAligned.vue';
import CatalogService, { Brand } from '@/services/catalog.service';

@Component({
  components: { VueperSlides, VueperSlide, VerticalAligned },
})
export default class Brands extends Vue {
    @Prop() private models!: Brand[];

    @Watch('models', { deep: true })
    onModelChange(newVal: Brand[]):void {
        this.$data.slides = newVal.filter( (model:Brand) => model.featured == true );
    }

    data() {
        return {
            title: 'Nuestras Marcas',
            btnLabel: 'Ver marcas',
            btnLink: '/marcas',
            slides: []
        }
    }

    hasButton():boolean {
        return this.$data.btnLabel != null && this.$data.btnLink != null;
    }

    getAssetPath(image:string):string {
        let images = require.context('../../assets/', true, /\.(gif|jpg|png|svg)$/);

        return images(image);

    }
}
