


















import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
import { Brand } from '@/services/catalog.service';

@Component
export default class OurProducts extends Vue {
    @Prop() private models!: Brand[];

    @Watch('models', { deep: true })
    onModelChange(newVal: Brand[]):void {
        this.$data.slides = newVal.filter( (model:Brand) => model.featured == true );
    }

    data() {
        return {
            slides: []
        }
    }
}
