











































import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'
import ProductItem from '@/components/products/ProductItem.vue';
import CatalogService, { Product } from '@/services/catalog.service';

@Component({
    components: { VueperSlides, VueperSlide, ProductItem }
})
export default class RelatedProducts extends Vue {
    @Prop() private model!: Product;

    @Watch('model', { deep: true })
    onModelChange(newVal: Product[]):void {
        this.updateRelatedModels();
    }

    created() {
        this.updateRelatedModels();
    }

    updateRelatedModels() {
        if(this.model != null) {
            CatalogService.getProductsByTags(this.model.tags, this.model.id).then((models:Product[]) => {
                this.$data.models = models;
            }).catch(error => {
                this.$data.models = [];
            });
        }
    }

    data() {
        return {
            models: []
        };
    }

    getAssetPath(image:string):string {
        let images = require.context('../../assets/', true, /\.(gif|jpg|png|svg)$/);
        return images(image);

    }
}
