







































































































































































































































import { Component, Vue } from 'vue-property-decorator';
import Parallax from '@/components/Parallax.vue';
import { MetaInfo } from 'vue-meta';

@Component({
  metaInfo(this: Terms):MetaInfo {
    return {
      title: this.getPageTitle(),
      meta: [
        { name: 'description', content: this.getPageDescription() }
      ]
    }
  },
  components: {
    Parallax,
  },
})
export default class Terms extends Vue {
    getPageTitle() {
      return `${this.$data.title} | Pacific Standard`;
    }

    getPageDescription() {
      return this.$data.description;
    }

    data() {
        return {
          title: 'Términos y condiciones',
          description: 'El presente documento constituye un contrato de adhesión para el uso de la página de Internet pacificstandard.mx (en adelante “Sitio Web” y/o “Página Web”) que celebran: por una parte, PACIFIC STANDARD SA DE CV',
        };
    }

    getAssetPath(image:string):string {
        let images = require.context('../assets/', true, /\.(gif|jpg|png|svg)$/);
        return images(image);
    }
}
