


























































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Validate, Validations } from 'vuelidate-property-decorators';
import { required, email, sameAs } from 'vuelidate/lib/validators'
import AuthService, { Identity } from '@/services/auth.service';
import MyAssistance from '@/components/my-account/MyAssistance.vue';
import { GenericResponse } from '@/services/interceptor';

@Component(
  {
    components: {
        MyAssistance
    }
  }
)
export default class Profile extends Vue {

  // @Validate({required, email})
  // email = '';

  // @Validate({required})
  // password = '';

    data() {
        return {
            form: {
            name: '',
            phone: '',
            email: '',
            password: '',
            password_confirm: '',
            },
            categories: [],
            updateProfileError: false,
            updateProfileComplete: false,
            updateProfileErrorMessage: '',
            identity: null
        };
    }

    @Validations()
    validations() {
        return {
            form: {
                name: { required },
                phone: { required },
                email: { email, required },
                password: {},
                password_confirm: {
                    passwordConfirm: sameAs('password')
                },
            }
        };
    }

    created() {
        const identity = AuthService.getIdentity();
        this.$data.identity = identity;
        this.$data.form.name = identity?.profile.name;
        this.$data.form.phone = identity?.profile.phone;
        this.$data.form.email = identity?.email;
    }

    onSubmit(event) {
        event.preventDefault()

        this!['$v'].$touch()
        let data = {...this.$data.form};
        console.log(data);

        if (!this!['$v'].$invalid) {
            AuthService.updateProfile(data).then((response:GenericResponse) => {
            if(response.success == true) {
                this.$data.updateProfileComplete = true;
                this.$data.updateProfileErrorMessage = '';
                this.$data.updateProfileError = false;
                // AuthService.setIdentity(response.data);
            } else {
                const keys = Object.keys(response.error);
                let msg = '';
                for(let i = 0; i < keys.length; i++) {
                msg += response.error[keys[i]].join('<br />');
                }

                this.$data.updateProfileErrorMessage = msg;
                this.$data.updateProfileError = true;
                this.$data.updateProfileComplete = false;
            }
            }, error => {
                this.$data.updateProfileErrorMessage = 'Error al actualizar tus datos.';
                this.$data.updateProfileError = true;
                this.$data.updateProfileComplete = false;
            });
        } else {
            this.$data.updateProfileErrorMessage = 'Error al actualizar tus datos.';
            this.$data.updateProfileError = true;
        }
    }
}
