




















import { Admin } from '@/services/auth.service';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class MyAssistance extends Vue {
    @Prop() private model!: Admin;

    data() {
        return {
        }
    }
}
