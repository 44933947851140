var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.models.length != 0)?_c('div',{attrs:{"id":"related-products"}},[_c('h3',{staticClass:"font-weight-bold text-uppercase text-warning mt-2"},[_vm._v("Productos relacionados")]),_c('hr'),_c('vueper-slides',{staticClass:"no-shadow",attrs:{"arrows":true,"bullets":false,"bullets-outside":false,"slide-multiple":"","visible-slides":4,"gap":2,"slide-ratio":1 / 4,"dragging-distance":200,"fixed-height":"350px","breakpoints":{ 
            3000: { slideRatio: 1 / 6,  visibleSlides: 6 },
            2000: { slideRatio: 1 / 5,  visibleSlides: 4 },
            1500: { slideRatio: 1 / 4,  visibleSlides: 4 },
            1200: { slideRatio: 1 / 4,  visibleSlides: 4 },
            900: { slideRatio: 1 / 2,  visibleSlides: 2 },
            850: { slideRatio: 1 / 2,  visibleSlides: 1 },
            700: { slideRatio: 1 / 2,  visibleSlides: 1 },
            500: { slideRatio: 1 / 1,  visibleSlides: 1 }
        }},scopedSlots:_vm._u([{key:"arrow-left",fn:function(){return [_c('BIconChevronLeft',{attrs:{"variant":"dark"}})]},proxy:true},{key:"arrow-right",fn:function(){return [_c('BIconChevronRight',{attrs:{"variant":"dark"}})]},proxy:true}],null,false,2602824009)},_vm._l((_vm.models),function(model,i){return _c('vueper-slide',{key:i,scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('div',{staticClass:"vueperslide__content-wrapper"},[_c('ProductItem',{attrs:{"model":model}})],1)]},proxy:true}],null,true)})}),1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }