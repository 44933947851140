



















import { Component, Vue } from 'vue-property-decorator';
import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'
import PostItem from '@/components/blog/PostItem.vue';
import BlogService, { Post } from '@/services/blog.service';


@Component({
  components: { VueperSlides, VueperSlide, PostItem },
})
export default class LastestNews extends Vue {
    data() {
        return {
            models: []
        }
    }

  created() {

    BlogService.getPosts().then((models:Post[]) => {
      this.$data.models = models;
    }).catch(error => {
      this.$data.models = [];
    });
  }
}
