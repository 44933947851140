






































































import { Product } from '@/services/catalog.service';
import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'
import VerticalAligned from '../VerticalAligned.vue';

@Component({
  components: { VueperSlides, VueperSlide, VerticalAligned },
})
export default class FeaturedProducts extends Vue {
    @Prop() private models!: Product[];

    @Watch('models', { deep: true })
    onModelChange(newVal: Product[]):void {
        this.$data.slides = newVal.filter( (model:Product) => model.featured == true );
    }

    data() {
        return {
            title: 'Conoce nuestros productos',
            description: 'Renovamos constantemente nuestro catálogo para brindarte la mejor calidad y variedad de productos con el fin de satisfacer cualquiera de tus necesidades.',
            subtitle: 'Descubre lo que tenemos para ti',
            btnLabel: 'Ver nuevos productos',
            btnLink: '/productos',
            slides: []
        }
    }

    hasButton():boolean {
        return this.$data.btnLabel != null && this.$data.btnLink != null;
    }
}
