



















































import { Component, Vue } from 'vue-property-decorator';
import BasketService, { Basket, Quote } from '@/services/basket.service';

import MyAssistance from '@/components/my-account/MyAssistance.vue';
import eventBus from '@/services/eventBus';

export interface QuotesQuery {
    page:           string|null;
}


@Component(
  {
    components: {
        MyAssistance
    }
  }
)
export default class Quotes extends Vue {

    data() {
        return {
            fields: [
                // { key: 'index', label: '#' },
                { key: 'status', label: 'Estatus' },
                { key: 'updated_at', label: 'Actualizada', thClass: 'w-25' },
                { key: 'request', label: 'Productos' },
                { key: 'quote', label: 'Descarga', tdClass: 'text-center', thClass: 'w-25' },
            ],
            page: 1,
            pageEnd: false,
            models: []
        };
    }

    created() {
      eventBus.$on('user-baskets-updated', () => {
        this.$data.page = 1;
        this.loadModels(this.$data.page);
      });

      this.loadModels(this.$data.page);
    }

    loadModels(page = 1):any {
      let config = {
        page: null,
      } as QuotesQuery;

      config.page = `${page}`;

      BasketService.getQuotes(config).then((models:Quote[]) => {
        this.$data.models = this.$data.models.concat(models);

        if(models.length == 0) {
          this.$data.pageEnd = true;
        }
      }).catch(error => {
        // this.$data.models = [];
      });
    }


    nextPage() {
      this.$data.page += 1;
      this.loadModels(this.$data.page);
    }

}
