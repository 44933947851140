import { render, staticRenderFns } from "./Quotes.vue?vue&type=template&id=12fe5530&scoped=true&"
import script from "./Quotes.vue?vue&type=script&lang=ts&"
export * from "./Quotes.vue?vue&type=script&lang=ts&"
import style0 from "./Quotes.vue?vue&type=style&index=0&id=12fe5530&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "12fe5530",
  null
  
)

export default component.exports