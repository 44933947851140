


























import { MetaInfo } from 'vue-meta';
import { Component, Vue } from 'vue-property-decorator';
import Login from '@/components/auth/Login.vue';
import ResetPassword from '@/components/auth/ResetPassword.vue';
import Signup from '@/components/auth/Signup.vue';
import eventBus from '@/services/eventBus';

@Component({
  metaInfo(this: Auth):MetaInfo {
    return {
      title: this.getPageTitle(),
      meta: [
        { name: 'description', content: this.getPageDescription() }
      ]
    }
  },
  components: {
    Login,
    ResetPassword,
    Signup
  },
})
export default class Auth extends Vue {

    getPageTitle() {
      return `${this.$data.title} | Pacific Standard`;
    }

    getPageDescription() {
      return this.$data.description;
    }

    data() {
        return {
          title: 'Identifícate como distribuidor',
          description: 'Inicia sesión si ya eres un distribuidor autorizado. O déjanos tus datos y analizaremos tu solicitud.',
          login: true
        };
    }

    created() {
      eventBus.$on('toggle-login', (login:boolean) => this.$data.login = login);
    }
}
