































































































import { Component, Vue } from 'vue-property-decorator';
import Breadcrumb from '@/components/Breadcrumb.vue';
import { gmapApi } from 'vue2-google-maps'
import CatalogService, { Distributor } from '@/services/catalog.service';
import { MetaInfo } from 'vue-meta';
import eventBus from '@/services/eventBus';

@Component({
  metaInfo(this: Distributors):MetaInfo {
    return {
      title: this.getPageTitle(),
      meta: [
        { name: 'description', content: this.getPageDescription() }
      ]
    }
  },
  components: {
    Breadcrumb
  },
})
export default class Distributors extends Vue {
    getPageTitle() {
      return `${this.$data.title} | Pacific Standard`;
    }

    getPageDescription() {
      return this.$data.description;
    }

    data() {
        return {
            title: 'Encuentra un distribuidor',
            description: 'Puedes encontrar nuestras marcas en todo el territorio Mexicano.',

            form: {
                zipcode: ''
            },
            results: [],
            choosed: -1,            
            infoOptions:  {
                content: '',
                pixelOffset: {
                    width: 0,
                    height: -35
                }
            },
            infoWindowPos: null,
            infoWinOpen: false,
            currentMidx: null,
            models: []
        };
    }

    created() {
        CatalogService.getDistributors().then((models:Distributor[]) => {
            this.$data.models = models;
            this.$data.results = models;
        });
    }

    mounted () {
        // At this point, the child GmapMap has been mounted, but
        // its map has not been initialized.

        // this.$data.results = this.$data.models;
        // if(this.$refs.mapRef!['$mapPromise'] != undefined) {
        //     eventBus.$emit('loading', true);
        //     navigator.geolocation.getCurrentPosition(position => {
        //         const location = { lat: position.coords.latitude, lng: position.coords.longitude };
        //         this.$refs.mapRef!['$mapPromise'].then(map => {
        //             if(map != undefined) {
        //                 Vue!['$geocoder'].getGoogleResponseFromLatLng(location, response => { 
        //                     const results = response.results.filter((result) => {
        //                         return result.types.indexOf('postal_code') != -1;
        //                     });

        //                     if(results.length != 0) {
        //                         const components = results[0].address_components.filter((address_component) => {
        //                             return address_component.types.indexOf('postal_code') != -1;
        //                         });
                                
        //                         if(components.length != 0) {
        //                             this.$data.form.zipcode = components[0].short_name;
        //                             this.search();
        //                         }
        //                     }
        //                     eventBus.$emit('loading', false);
        //                 }, err => {
        //                     eventBus.$emit('loading', false);
        //                 });
        //             }
        //         });
        //     }, error => {
        //         // console.log(error);
        //         eventBus.$emit('loading', false);
        //     });
        // }
    }

    search() {
        this.$data.results = this.$data.models.filter( (model) => {
            // Find zipcode by first 3 characters
                let zipcode = this.$data.form.zipcode;
                if(zipcode.length >= 3) {
                    zipcode = zipcode.substring(0, 3);
                }

            return model.zipcodes.join(',').indexOf(zipcode) != -1;
        });

        if(this.$data.results.length != 0) {
            this.choose(this.$data.results[0], 12);
        }
    }

    choose(index:number, zoom = 16) {
        this.$refs.mapRef!['$mapPromise'].then(map => {
            if(map != undefined && this.$data.results[index] != undefined) {
                map.setCenter({ lat: this.$data.results[index].lat, lng: this.$data.results[index].lng });
                map.setZoom(zoom);
                this.$data.choosed = index;
                if(zoom >= 16) {
                    this.$data.infoWindowPos = { lat: this.$data.results[index].lat, lng: this.$data.results[index].lng };
                    this.$data.infoOptions.content = `<h6>${this.$data.results[index].name}</h6><p>${this.$data.results[index].address} <br/>Tel: ${this.$data.results[index].phone}</p>`;

                    //check if its the same marker that was selected if yes toggle
                    if (this.$data.currentMidx == index) {
                        this.$data.infoWinOpen = !this.$data.infoWinOpen;
                    }
                    //if different marker set infowindow to open and reset current marker index
                    else {
                        this.$data.infoWinOpen = true;
                        this.$data.currentMidx = index;
                    }
                }
            }
        });
    }

    getAssetPath(image:string):string {
        let images = require.context('../assets/', true, /\.(gif|jpg|png|svg)$/);
        return images(image);
    }

}
