













































import { Component, Vue } from 'vue-property-decorator';
import Parallax from '@/components/Parallax.vue';
import ProductItem from '@/components/products/ProductItem.vue';
import Filters from '@/components/products/Filters.vue';
import Breadcrumb from '@/components/Breadcrumb.vue';
import PostItem from '@/components/blog/PostItem.vue';
import BlogService, { BlogQuery, Post } from '@/services/blog.service';
import { MetaInfo } from 'vue-meta';

@Component({
  metaInfo(this: Blog):MetaInfo {
    return {
      title: this.getPageTitle(),
      meta: [
        { name: 'description', content: this.getPageDescription() }
      ]
    }
  },
  components: {
    Parallax,
    Breadcrumb,
    ProductItem,
    Filters,
    PostItem
  },
})
export default class Blog extends Vue {
    getPageTitle() {
      return `${this.$data.title} | Pacific Standard`;
    }

    getPageDescription() {
      return this.$data.description;
    }

    data() {
        return {
          title: 'Últimas noticias',
          description: 'Consulta las últimas noticias de nuestra empresa.',

          breadcrumbPath: [
            {
              text: 'Inicio',
              href: '/'
            },
            {
              text: 'Blog',
              active: true
            }
          ],
          page: 1,
          pageEnd: false,
          posts: []
        };
    }

    nextPage() {
      this.$data.page += 1;
      this.loadModels(this.$data.page);
    }

    created() {
      this.loadModels(this.$data.page);
    }

    loadModels(page = 1):any {
      let config = {
        page: null,
      } as BlogQuery;

      config.page = `${page}`;

      BlogService.getPosts(config).then((models:Post[]) => {
        this.$data.posts = this.$data.posts.concat(models);

        if(models.length == 0) {
          this.$data.pageEnd = true;
        }
      }).catch(error => {
        // this.$data.models = [];
      });
    }

    getAssetPath(image:string):string {
        let images = require.context('../assets/', true, /\.(gif|jpg|png|svg)$/);
        return images(image);
    }
}
