
















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class PostItem extends Vue {
    @Prop() private model!: any;

    data() {
        return {
        }
    }

    getAssetPath(image:string):string {
        let images = require.context('../../assets/', true, /\.(gif|jpg|png|svg)$/);
        return images(image);

    }
}
