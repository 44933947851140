






import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
    components: {  }
})
export default class Breadcrumb extends Vue {
    @Prop() private path!: any[];

    data() {
        return {
        };
    }
}
