var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"signup"}},[_c('h1',[_vm._v("¿Quieres convertirte en distribuidor?")]),_c('p',{staticClass:"mt-0 mb-3"},[_vm._v("Regístrate y nos pondremos en contacto contigo los más pronto posible.")]),_c('b-form',{on:{"submit":_vm.onSubmit}},[_c('b-form-group',{attrs:{"id":"signup-input-group-name","label":"Nombre(s):","label-for":"input-name"}},[_c('b-form-input',{class:{ 
              'is-invalid': _vm.$v.form.name.$dirty && _vm.$v.form.name.$error,
              'is-valid': _vm.$v.form.name.$dirty && !_vm.$v.form.name.$error, 
            },attrs:{"id":"signup-input-name","type":"text","placeholder":"Tu nombre completo"},on:{"input":_vm.$v.form.name.$touch},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),(_vm.$v.form.name.$error)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Debe ingresar su(s) nombre(s) ")]):_vm._e()],1),_c('b-form-group',{attrs:{"id":"signup-input-group-company","label":"Empresa / negocio:","label-for":"signup-input-company"}},[_c('b-form-input',{class:{ 
              'is-invalid': _vm.$v.form.company.$dirty && _vm.$v.form.company.$error,
              'is-valid': _vm.$v.form.company.$dirty && !_vm.$v.form.company.$error, 
            },attrs:{"id":"signup-input-company","type":"text","placeholder":"Nombre de tu empresa / negocio"},on:{"input":_vm.$v.form.company.$touch},model:{value:(_vm.form.company),callback:function ($$v) {_vm.$set(_vm.form, "company", $$v)},expression:"form.company"}}),(_vm.$v.form.company.$error)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Debe ingresa el nombre de tu empresa/negocio ")]):_vm._e()],1),_c('b-form-group',{attrs:{"id":"signup-input-group-phone","label":"Teléfono:","label-for":"signup-input-phone"}},[_c('b-form-input',{class:{ 
              'is-invalid': _vm.$v.form.phone.$dirty && _vm.$v.form.phone.$error,
              'is-valid': _vm.$v.form.phone.$dirty && !_vm.$v.form.phone.$error, 
            },attrs:{"id":"signup-input-phone","type":"text","placeholder":"Teléfono a 10 dígitos"},on:{"input":_vm.$v.form.phone.$touch},model:{value:(_vm.form.phone),callback:function ($$v) {_vm.$set(_vm.form, "phone", $$v)},expression:"form.phone"}}),(_vm.$v.form.phone.$error)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Debe ingresar su teléfono ")]):_vm._e()],1),_c('b-form-group',{attrs:{"id":"signup-input-group-location","label":"Ubicación:","label-for":"signup-input-location"}},[_c('b-form-input',{class:{ 
              'is-invalid': _vm.$v.form.location.$dirty && _vm.$v.form.location.$error,
              'is-valid': _vm.$v.form.location.$dirty && !_vm.$v.form.location.$error, 
            },attrs:{"id":"signup-input-location","type":"text","placeholder":"Estado / Ciudad / Colonia / CP"},model:{value:(_vm.form.location),callback:function ($$v) {_vm.$set(_vm.form, "location", $$v)},expression:"form.location"}}),(_vm.$v.form.location.$error)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Debemos saber cual es la zona en la que le interesa distribuir nuestros productos. ")]):_vm._e()],1),_c('b-form-group',{attrs:{"id":"signup-input-group-email","label":"Correo electrónico:","label-for":"signup-input-email"}},[_c('b-form-input',{class:{ 
              'is-invalid': _vm.$v.form.email.$dirty && _vm.$v.form.email.$error,
              'is-valid': _vm.$v.form.email.$dirty && !_vm.$v.form.email.$error, 
            },attrs:{"id":"signup-input-email","type":"email","placeholder":"Tu email"},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}}),(_vm.$v.form.email.$error)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" No ha ingresado un email válido. ")]):_vm._e()],1),_c('b-form-group',{attrs:{"id":"signup-input-group-password","label":"Contraseña:","label-for":"input-password"}},[_c('b-form-input',{class:{ 
              'is-invalid': _vm.$v.form.password.$dirty && _vm.$v.form.password.$error,
              'is-valid': _vm.$v.form.password.$dirty && !_vm.$v.form.password.$error, 
            },attrs:{"id":"signup-input-password","type":"password","placeholder":"Tu contraseña","autocomplete":"off"},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}}),(_vm.$v.form.password.$error)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" La contraseña debe ser de al menos seis caracteres. ")]):_vm._e()],1),_c('b-form-group',{attrs:{"id":"signup-input-group-password_confirm","label":"Confirmar contraseña:","label-for":"input-password_confirm"}},[_c('b-form-input',{class:{ 
              'is-invalid': _vm.$v.form.password_confirm.$dirty && _vm.$v.form.password_confirm.$error,
              'is-valid': _vm.$v.form.password_confirm.$dirty && !_vm.$v.form.password_confirm.$error, 
            },attrs:{"id":"signup-input-password-confirm","type":"password","placeholder":"Repite tu contraseña","autocomplete":"off"},model:{value:(_vm.form.password_confirm),callback:function ($$v) {_vm.$set(_vm.form, "password_confirm", $$v)},expression:"form.password_confirm"}}),(_vm.$v.form.password_confirm.$error)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Debe repetir exactamente la contraseña. ")]):_vm._e()],1),_c('b-form-group',{attrs:{"id":"signup-input-group-categories","label":"Categoría de interés:","label-for":"signup-input-categories"}},[_c('b-form-checkbox-group',{class:{ 
              'is-invalid': _vm.$v.form.categories.$dirty && _vm.$v.form.categories.$error,
              'is-valid': _vm.$v.form.categories.$dirty && !_vm.$v.form.categories.$error, 
            },attrs:{"options":_vm.categories},model:{value:(_vm.form.categories),callback:function ($$v) {_vm.$set(_vm.form, "categories", $$v)},expression:"form.categories"}}),(_vm.$v.form.categories.$error)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Debe seleccionar al menos una categoría. ")]):_vm._e()],1),_c('b-button',{attrs:{"type":"submit","size":"sm","variant":"primary"}},[_vm._v("Enviar mi solicitud")]),(_vm.signupError != null && _vm.signupError == true)?_c('div',{staticClass:"alert alert-danger mt-3",attrs:{"role":"alert"}},[_vm._v(" "+_vm._s(_vm.signupErrorMessage)+" ")]):_vm._e(),(_vm.signupComplete == true)?_c('div',{staticClass:"alert alert-success mt-3",attrs:{"role":"alert"}},[_vm._v(" Se ha enviado su solicitud correctamente. Te contactaremos lo antes posible. ")]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }