



















































import { Component, Vue } from 'vue-property-decorator';
import Parallax from '@/components/Parallax.vue';
import { MetaInfo } from 'vue-meta';

@Component({
  metaInfo(this: Privacy):MetaInfo {
    return {
      title: this.getPageTitle(),
      meta: [
        { name: 'description', content: this.getPageDescription() }
      ]
    }
  },
  components: {
    Parallax,
  },
})
export default class Privacy extends Vue {
    getPageTitle() {
      return `${this.$data.title} | Pacific Standard`;
    }

    getPageDescription() {
      return this.$data.description;
    }

    data() {
        return {
          title: 'Aviso de privacidad',
          description: 'El Presente Aviso de Privacidad se suscribe en la entidad de Tlaquepaque, Jalisco, México, con nombre comercial PACIFIC STANDARD  S.A. de C.V.,',
        };
    }

    getAssetPath(image:string):string {
        let images = require.context('../assets/', true, /\.(gif|jpg|png|svg)$/);
        return images(image);
    }
}
