



























































import { Component, Watch, Vue } from 'vue-property-decorator';
import TopBanner, { TopBannerBackground } from '@/components/TopBanner.vue';
import ProductItem from '@/components/products/ProductItem.vue';
import Filters, { AppliedFilters } from '@/components/products/Filters.vue';
import Breadcrumb from '@/components/Breadcrumb.vue';
import CatalogService, { Brand, Category, Product, ProductQuery } from '@/services/catalog.service';
import eventBus from '@/services/eventBus';
import { MetaInfo } from 'vue-meta';

@Component({
  metaInfo(this: Products):MetaInfo {
    return {
      title: this.getPageTitle(),
      meta: [
        { name: 'description', content: this.getPageDescription() }
      ]
    }
  },
  components: {
    TopBanner,
    Breadcrumb,
    ProductItem,
    Filters
  },
})
export default class Products extends Vue {
    getPageTitle() {
      return `${this.$data.title} | Pacific Standard`;
    }

    getPageDescription() {
      return this.$data.description;
    }

    data() {
        return {
          title: 'Nuestros productos',
          description: 'Pacific Standard es una marca especializada en equipo para soldar, contamos con una amplia variedad de productos para que encuentres todo lo que necesitas.',

          breadcrumbPath: [
            {
              text: 'Inicio',
              href: '/'
            },
            {
              text: 'Productos',
              href: '/productos'
            }
          ],
          brand: null,
          category: null,
          filters: {
            brandSelected: [],
            categorySelected: []
          } as AppliedFilters,
          page: 1,
          pageEnd: false,
          models: [],
          items: [],
          parallax: {
            background:               this.getAssetPath('./dummy/products/banner.jpg'),
            backgroundSize:           '100%',
            backgroundMobile:         this.getAssetPath('./dummy/products/banner-mobile.jpg'),
            backgroundMobileSize:     'cover'
          } as TopBannerBackground
        };
    }

    created() {
      eventBus.$on('filters-updated', (filters:AppliedFilters) => { this.$data.filters = filters; });
      this.$data.page = this.$route.params?.page != undefined ? Number.parseInt(this.$route.params?.page) : 1;
      this.loadModels(this.$data.page);
    }

    applyFilters() {
      this.$data.items = this.$data.models
          .filter((product:Product) => this.$data.filters.brandSelected.length == 0 || (product?.brand?.id != undefined && this.$data.filters.brandSelected.indexOf(product?.brand?.id) != -1 ) )
          .filter((product:Product) => this.$data.filters.categorySelected.length == 0 || (product?.category?.id != undefined && this.$data.filters.categorySelected.indexOf(product?.category?.id) != -1 ) );
    }

    @Watch('$route.params', { deep: true })
    onModelChange(newVal:any):void {
        this.$data.models = [];
        this.$data.items = [];
        this.$data.filters = {
          brandSelected: [],
          categorySelected: []
        } as AppliedFilters;
        
        this.loadModels();
    }

    @Watch('models', { deep: true })
    onModelsChange(filters: number[]):void {
        this.applyFilters()
    }

    @Watch('filters', { deep: true })
    onFiltersChange(filters: number[]):void {
        this.applyFilters()
    }

    nextPage() {
      this.$data.page += 1;
      this.loadModels(this.$data.page);
    }

    loadModels(page = 1):any {
      let config = {
        brand_id: null,
        category_id: null,
        search: null,
        page: null,
      } as ProductQuery;

      config.search = this.$route.params?.search != undefined ? this.$route.params?.search : null;
      config.brand_id = this.$route.params?.brand_id != undefined ? this.$route.params?.brand_id : null;
      config.category_id = this.$route.params?.category_id != undefined ? this.$route.params?.category_id : null;
      config.page = `${page}`;

      CatalogService.getProducts(config).then((models:Product[]) => {
        this.$data.models = this.$data.models.concat(models);

        // Set parallax background
        if(this.$data.models.length != 0) {
          if(config.brand_id != null && this.$data.models[0].brand != null) {
            this.setBrand(this.$data.models[0].brand);
          }

          if(config.category_id != null && this.$data.models[0].category != null) {
            this.setCategory(this.$data.models[0].category);
          }
        }

        if(models.length == 0) {
          this.$data.pageEnd = true;
        }
      }).catch(error => {
        // this.$data.models = [];
      });
    }

    setBrand(brand:Brand) {
      this.$data.brand = brand;
      this.$data.parallax = {
        background:               brand.parallax,
        backgroundSize:           'contain',
        backgroundMobile:         brand.parallax,
        backgroundMobileSize:     '200%'
      } as TopBannerBackground;

      this.$data.title = brand.name;
      this.$data.description = brand.description;

      this.$data.breadcrumbPath = [
        {
          text: 'Inicio',
          href: '/'
        },
        {
          text: 'Productos',
          href: '/productos'
        },
        {
          text: brand.name,
          active: true
        }
      ];
    }

    setCategory(category:Category) {
      this.$data.category = category;
      this.$data.parallax = {
        background:               category.parallax,
        backgroundSize:           'contain',
        backgroundMobile:         category.parallax,
        backgroundMobileSize:     '200%'
      } as TopBannerBackground;

      this.$data.title = category.name;
      this.$data.description = category.description;

      this.$data.breadcrumbPath = [
        {
          text: 'Inicio',
          href: '/'
        },
        {
          text: 'Productos',
          href: '/productos'
        },
        {
          text: category.name,
          active: true
        }
      ];
    }

    getAssetPath(image:string):string {
        let images = require.context('../assets/', true, /\.jpg$/);
        return images(image);
    }
}
