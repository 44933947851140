import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import eventBus from './eventBus';
import AuthService, { Identity } from '@/services/auth.service';

export interface GenericResponse {
    success:           boolean;
    data:              any;
    error:             any;
}


axios.interceptors.request.use((request:AxiosRequestConfig) => {

    request.baseURL = process.env.VUE_APP_API_URL;

    // Add auth headers when no login request
    if(request!.url!.includes('default/is-logged-in')
        || request!.url!.includes('default/profile')
        || request!.url!.includes('default/update-profile')
        || request!.url!.includes('quote-request/index')
        || request!.url!.includes('quote-request/create')) {
        const identity:Identity|null = AuthService.getIdentity();
        
        if(identity != null) {
            if(request.params != undefined) {
                request.params['access-token'] = identity.access_token;
            } else {
                request.params = { 'access-token': identity.access_token };
            }
        }
    }

    eventBus.$emit('loading', true);
    return request;
}, function(err) {
    return Promise.reject(err);
});


axios.interceptors.response.use((successRes:AxiosResponse) => {
        eventBus.$emit('loading', false);
        return successRes!.data;
    }, 
    function(error) {
        eventBus.$emit('loading', false);        
        return Promise.reject(error);
    }
);