import Cookies from "js-cookie";
import eventBus from '@/services/eventBus';
import axios from "axios";
import { Product } from "./catalog.service";
import { GenericResponse } from "./interceptor";


export interface Quote {
    id:         number;
    status:     number;
    created_at: number;
    updated_at: number;
    user:       User;
    admin:      User;
    request:    Request;
    quote:      string|null;
}

export interface User {
    id:        number;
    name:      string;
    phone:     string;
    cellphone: string;
    email:     string;
}

export interface Request {
    items: BasketItem[];
}

export interface BasketItem {
    qty:         number; 
    id:         number; 
    sku:       string; 
    name:       string; 
    slug:       string; 
    picture:    string; 
    brand:      string; 
}

export interface Basket {
    items:  BasketItem[];
}


class BasketService {

    getBasket():Basket {
        const data = Cookies.get('basket');
        if(data != undefined && data != 'undefined') {
            return JSON.parse(data) as Basket;
        }

        return { items: [] } as Basket;
    }

    setBasket(basket:Basket):boolean {
        const oldBasket = this.getBasket();
        Cookies.remove('basket');
        Cookies.set('basket', JSON.stringify(basket));
        basket = this.getBasket();
        eventBus.$emit('basket-updated', basket);
        return true;
    }

    addItem(qty:number, product:Product):boolean {
        let basket = this.getBasket();
        const ii = basket.items.findIndex((bi:BasketItem) => bi.id == product.id);
        if(ii == -1) {
            basket.items.push({
                qty: qty,
                id: product.id,
                sku: product.sku,
                name: product.name,
                slug: product.slug,
                picture: product.picture?.thumb_picture,
                brand: product.brand?.name
            } as BasketItem);
            return this.setBasket(basket);
        } else{
            const iqty = basket.items[ii].qty * 1;
            qty = qty * 1;

            basket.items[ii].qty = iqty + qty;
            return this.setBasket(basket);
        }
    }

    removeItem(index:number):boolean {
        let basket = this.getBasket();
        if(basket.items[index] != undefined) {
            basket.items.splice(index, 1);
            return this.setBasket(basket);
        }

        return false;
    }

    clear():boolean {
        const basket = { items: [] } as Basket;
        return this.setBasket(basket);
    }

    getQuotes(params:any={}):Promise<Quote[]> {
        const config = {
            params: params
        }

        return axios.get(`/v1/quote-request/index`, config);
    }

    confirm(data:any):Promise<GenericResponse> {
        return axios.post('/v1/quote-request/create', data);
    }
}

export default new BasketService();
