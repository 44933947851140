


























































































































































import { Component, Vue } from 'vue-property-decorator';
import CatalogService, { Category } from '@/services/catalog.service';
import { Validate, Validations } from 'vuelidate-property-decorators';
import { required, email, minLength, sameAs } from 'vuelidate/lib/validators'
import AuthService, { Identity } from '@/services/auth.service';
import { GenericResponse } from '@/services/interceptor';

export interface CategoryOption { 
  value: number;
  text: string;
}

@Component(
  {
    components: { }
  }
)
export default class Signup extends Vue {

    data() {
        return {
          form: {
            name: '',
            company: '',
            phone: '',
            location: '',
            email: '',
            password: '',
            password_confirm: '',
            categories: []
          },
          categories: [],
          signupError: false,
          signupComplete: false,
          signupErrorMessage: ''
        };
    }

    @Validations()
    validations() {
      return {
          form: {
            name: { required },
            company: { required },
            phone: { required },
            location: { required },
            email: { email, required },
            password: { required },
            password_confirm: {
              required,
              passwordConfirm: sameAs('password')
            },
            categories: { required }
          }
      };
    }

    created() {
        CatalogService.getCategories().then((models:Category[]) => {
          this.$data.categories = models.map( (model:Category) => {
            return { value: model.id, text: model.name } as CategoryOption;
          });
        }).catch(error => {
            this.$data.categories = [];
        });
    }

  onSubmit(event) {
    event.preventDefault()

    this.$data.signupComplete = false;
    this.$data.signupError = false;
    this.$data.signupErrorMessage = '';

    this!['$v'].$touch()
    if (!this!['$v'].$invalid) {
      let data = {...this.$data.form};
      
      let categories = this.$data.categories.filter((category:CategoryOption) => data.categories.indexOf(category.value) != -1);
      categories = categories.map((category:CategoryOption) => category.text);
      data.categories = categories.join(', ');

      AuthService.signup(data).then((response:GenericResponse) => {
        if(response.success == true) {
          this.$data.signupComplete = true;
          this.$data.form.name = '';
          this.$data.form.company = '';
          this.$data.form.phone = '';
          this.$data.form.location = '';
          this.$data.form.email = '';
          this.$data.form.password = '';
          this.$data.form.password_confirm = '';
          this.$data.form.categories = [];
          this!['$v'].$reset()
        } else {
          const keys = Object.keys(response.error);
          let msg = '';
          for(let i = 0; i < keys.length; i++) {
            msg += response.error[keys[i]].join('<br />');
          }

          this.$data.signupErrorMessage = msg;
          this.$data.signupError = true;
        }

      }, (error) => {
        this.$data.signupErrorMessage = 'Error al generar la cuenta.';
        this.$data.signupError = true;
      });
    } else {
      console.log('error')
    }
  }
}
