



















































import { Component, Vue } from 'vue-property-decorator';
import VerticalAligned from './VerticalAligned.vue';

@Component({
    components: { VerticalAligned }
})
export default class Footer extends Vue {
}
