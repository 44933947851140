
































import { MetaInfo } from 'vue-meta';
import { Component, Vue } from 'vue-property-decorator';


@Component({
  metaInfo(this: Auth):MetaInfo {
    return {
      title: this.getPageTitle(),
      meta: [
        { name: 'description', content: this.getPageDescription() }
      ]
    }
  },
  components: {
  },
})
export default class Auth extends Vue {

    getPageTitle() {
      return `${this.$data.title} | Pacific Standard`;
    }

    getPageDescription() {
      return this.$data.description;
    }

    data() {
        return {
          title: 'Recuperar contraseña',
          description: 'Respuesta a tu solicitud de recuperar contraseña',
          success: null
        };
    }

    created() {
        this.$data.success = this.$route.params.success == 'success' ? true: false;
    }
}
