



















import { MetaInfo } from 'vue-meta';
import { Component, Vue } from 'vue-property-decorator';
import Quotes from  '@/components/my-account/Quotes.vue';

@Component({
  metaInfo(this: Auth):MetaInfo {
    return {
      title: this.getPageTitle(),
      meta: [
        { name: 'description', content: this.getPageDescription() }
      ]
    }
  },
  components: {
    Quotes
  },
})
export default class Auth extends Vue {
    getPageTitle() {
      return `${this.$data.title} | Pacific Standard`;
    }

    getPageDescription() {
      return this.$data.description;
    }

    data() {
        return {
          title: 'Mis cotizaciones',
          description: 'Consulta aquí las cotizaciones solicitadas usando nuestro sitio web.',
        };
    }
}
