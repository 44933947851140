import { render, staticRenderFns } from "./MyAssistance.vue?vue&type=template&id=48276220&scoped=true&"
import script from "./MyAssistance.vue?vue&type=script&lang=ts&"
export * from "./MyAssistance.vue?vue&type=script&lang=ts&"
import style0 from "./MyAssistance.vue?vue&type=style&index=0&id=48276220&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48276220",
  null
  
)

export default component.exports