


















































































import { Component, Prop, Vue } from 'vue-property-decorator';
import AuthService, { Identity } from '@/services/auth.service';
import BasketItems from '@/components/my-account/BasketItems.vue';
import BasketService, { Basket } from '@/services/basket.service';
import eventBus from '@/services/eventBus';

@Component({
    components: { BasketItems }
})
export default class Header extends Vue {
  @Prop() private active!: string;

    data() {
        return {
            isSearchActive: false,
            search: null,
            identity: null,
            showBasketSidebar: false
        };
    }

    toggleSearch() {
        this.$data.isSearchActive = !this.$data.isSearchActive;
    }

    created() {
        eventBus.$on('identity-updated', (identity:Identity) => this.$data.identity = identity);
        this.$data.identity = AuthService.getIdentity();
    }

    toggleBasketSidebar() {
        eventBus.$emit('toggle-basket-sidebar');
    }

    signout() {
        AuthService.setIdentity(null);
    }

    onSearch(event) {
      event.preventDefault();
      const to = `/productos/buscar/${this.$data.search}`;
      if(this.$route.path.indexOf('/productos/buscar') == -1) {
        this.$router.push(to);
      } else {
        if(to != this.$route.path) {
            this.$router.replace(to);
        }
      }
    }
}
