















declare global {
  interface Window {
    FB:any;
    fbAsyncInit:any;
  }
}

import { Component, Vue } from 'vue-property-decorator';
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import { MetaInfo } from 'vue-meta';

@Component({
  metaInfo(this: App):MetaInfo {
    return {
      meta: [
        { name: 'facebook-domain-verify', content: '0f6g0rqj8ph4mpw8tndhexcouy161u' },
      ]
    }
  },
  components: {
    Header,
    Footer
  },
})
export default class App extends Vue {
  mounted() {
    var chatbox = document.querySelector('#fb-customer-chat')
    if(chatbox) {
      chatbox.setAttribute("page_id", "449973802045439")
      chatbox.setAttribute("attribution", "biz_inbox")
    }

    (function(d, s, id) {
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s); js.id = id;
      js.src = 'https://connect.facebook.net/es_LA/sdk/xfbml.customerchat.js';
      js.crossorigin = true

      if(fjs && fjs.parentNode) {
        fjs.parentNode.insertBefore(js, fjs);
      }

    }(document, 'script', 'facebook-jssdk'));

    window.fbAsyncInit = () => {
      window.FB.init({
        xfbml : true,
        version : 'v15.0'
      });
    };
  }
}
