








































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Validate, Validations } from 'vuelidate-property-decorators';
import { required, email } from 'vuelidate/lib/validators'
import AuthService, { Identity } from '@/services/auth.service';
import eventBus from '@/services/eventBus';
import { GenericResponse } from '@/services/interceptor';

@Component(
  {
    components: { }
  }
)
export default class ResetPassword extends Vue {

  // @Validate({required, email})
  // email = '';

  // @Validate({required})
  // password = '';

  data() {
      return {
        form: {
          email: null,
        },
        resetPasswordError: null,
        sent: false,
        success: null
      }
  }

  @Validations()
  validations() {
    return {
        form: {
          email: { email, required },
        }
    };
  }

  onSubmit(event) {
    event.preventDefault()

    this!['$v'].$touch()
    if (!this!['$v'].$invalid) {
      this.$data.sent = true;  
      AuthService.passwordResetRequest(this.$data.form.email).then((response:GenericResponse) => {
        this.$data.success = response.success;
        if(response.success == true) {
            // Correcto
            this.$data.form.email = null
            this!['$v'].$reset()
        } else {
            this.$data.resetPasswordError = true;
        }
      }, error => {
        this.$data.resetPasswordError = true;
        this.$data.sent = false;
        this.$data.success = null;
      });
    } else {
        this.$data.sent = false;
        this.$data.success = null;
        this.$data.resetPasswordError = true;
    }
  }

  toggleLogin() {
    eventBus.$emit('toggle-login', true);
  }
}
