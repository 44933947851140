
































import { Product } from '@/services/catalog.service';
import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
import eventBus from '@/services/eventBus';
import axios from "axios";


export interface AppliedFilters {
    brandSelected:          number[];
    categorySelected:       number[];
}

export interface ValeOption {
    value:          number;
    text:           string;
}

@Component
export default class Filters extends Vue {
    @Prop() private models!: Product[];

    data() {
        return {
          brandSelected: [], // Must be an array reference!
          brandOptions: [],
          categorySelected: [], // Must be an array reference!
          categoryOptions: [],
        }
    }

    @Watch('brandSelected', { deep: true })
    onBrandSelectedChange(brandSelected: number[]):void {
        eventBus.$emit('filters-updated', {
          brandSelected,
          categorySelected: this.$data.categorySelected
        } as AppliedFilters);
    }

    @Watch('categorySelected', { deep: true })
    onCategorySelectedChange(categorySelected: number[]):void {
        eventBus.$emit('filters-updated', {
          brandSelected: this.$data.brandSelected,
          categorySelected: categorySelected
        } as AppliedFilters);
    }

    @Watch('models', { deep: true })
    async onModelChange(models: Product[]):Promise<void> {
        this.$data.brandOptions = [];
        this.$data.categoryOptions = [];

        let productBrands:Product[] = []; 
        for(let i = 0; i < models.length; i++) {
          if( productBrands.findIndex((product:Product) =>  product?.brand?.id == models[i]?.brand?.id ) == -1 ) {
            productBrands.push(models[i]);
          }
        }

        this.$data.brandOptions = productBrands.map((product:Product) => {
          return { value: product.brand.id, text: product.brand.name } as ValeOption;
        });

        let productCategories:Product[] = []; 
        for(let i = 0; i < models.length; i++) {
          if( productCategories.findIndex((product:Product) =>  product?.category?.id == models[i]?.category?.id ) == -1 ) {
            productCategories.push(models[i]);
          }
        }

        // this.$data.categoryOptions = productCategories.map((product:Product) => {
        //   return { value: product.category.id, text: product.category.name } as ValeOption;
        // });

        this.$data.categoryOptions = await axios.get(`/v1/category`)
    }
}
