import axios from "axios";

export interface Banner {
    id:           number;
    video_id:     number;
    featured:     number;
    name:         string;
}

class BannerService {
    getOne():Promise<Banner> {
        return axios.get(`/v1/banner/index`);
    }
}

export default new BannerService();