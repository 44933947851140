import axios from "axios";
import Cookies from "js-cookie";
import eventBus from '@/services/eventBus';
import { GenericResponse } from "./interceptor";
import { Distributor } from "./catalog.service";

export interface Identity {
    id:           number;
    email:        string;
    access_token: string;
    profile:      Profile;
}

export interface Admin {
    id:           string;
    name:         string;
    phone:        string;
    cellphone:    string;
    email:        string;
}

export interface Profile {
    name:        string;
    phone:       string;
    admin:       Admin;
    distributor: Distributor;
}

class AuthService {

    setIdentity(identity:Identity|null) {
        Cookies.set('identity', JSON.stringify(identity));
        eventBus.$emit('identity-updated', identity);
    }
    
    getIdentity():Identity|null {
        const data = Cookies.get('identity');
        if(data != undefined && data != 'undefined') {
            const identity = JSON.parse(data) as Identity;
            eventBus.$emit('identity-updated', identity);
            return identity;
        }

        return null;
    }

    login(username:string, password:string):Promise<Identity> {
        const data = { username, password };
        return axios.post('/v1/default/login', data);
    }

    passwordResetRequest(email:string):Promise<GenericResponse> {
        const data = { email };
        return axios.post('/v1/default/password-reset-request', data);
    }

    signup(data:any):Promise<GenericResponse> {
        return axios.post('/v1/default/signup', data);
    }

    isLoggedIn():Promise<GenericResponse> {
        const identity = this.getIdentity();
        if(identity != null) {
            return axios.get('/v1/default/is-logged-in');
        }

        return Promise.resolve({ success: false } as GenericResponse);
    }

    updateProfile(data:any):Promise<GenericResponse> {
        return axios.post('/v1/default/update-profile', data);
    }

    updateIdentity():Promise<Identity> {
        return axios.get('/v1/default/profile');
    }
}

export default new AuthService();
