import { render, staticRenderFns } from "./PasswordResetResponse.vue?vue&type=template&id=4a8c9e11&scoped=true&"
import script from "./PasswordResetResponse.vue?vue&type=script&lang=ts&"
export * from "./PasswordResetResponse.vue?vue&type=script&lang=ts&"
import style0 from "./PasswordResetResponse.vue?vue&type=style&index=0&id=4a8c9e11&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4a8c9e11",
  null
  
)

export default component.exports