import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faFacebook, faInstagramSquare, faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import * as VueGoogleMaps from 'vue2-google-maps'
import Geocoder from "@pderas/vue2-geocoder";
import './scss/bootstrap.scss';
import './services/interceptor.ts';
import VueMeta from 'vue-meta'
import Vuelidate from 'vuelidate'
import VueGtag from "vue-gtag";
import moment from 'moment-timezone'

moment.tz.setDefault("America/Mexico_City");
moment.locale('es')

library.add(faFacebook)
library.add(faInstagramSquare)
library.add(faWhatsapp)
library.add(faMapMarkerAlt)

Vue.component('font-awesome-icon', FontAwesomeIcon)

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

Vue.use(VueGtag, {
  config: { id: "UA-194232824-1" }
}, router);

Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOLE_API_KEY,
    libraries: 'map', // This is required if you use the Autocomplete plugin
    // OR: libraries: 'places,drawing'
    // OR: libraries: 'places,drawing,visualization'
    // (as you require)

    //// If you want to set the version, you can do so:
    // v: '3.26',
  },

  //// If you intend to programmatically custom event listener code
  //// (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
  //// instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
  //// you might need to turn this on.
  // autobindAllEvents: false,

  //// If you want to manually install components, e.g.
  //// import {GmapMarker} from 'vue2-google-maps/src/components/marker'
  //// Vue.component('GmapMarker', GmapMarker)
  //// then set installComponents to 'false'.
  //// If you want to automatically install all the components this property must be set to 'true':
  installComponents: true
})

Vue.use(Geocoder, {
  googleMapsApiKey:   process.env.VUE_APP_GOOLE_API_KEY
});

Vue.use(VueMeta)

Vue.use(Vuelidate)

Vue.config.productionTip = false

Intl.NumberFormat

Vue.filter('shortdate', function (value:number) {
  if (!value) return ''
  return moment.unix(value).calendar();
})

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
