










import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class VerticalAligned extends Vue {
  @Prop() private height!: string;
}
