var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"profile"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('h1',[_vm._v("Actualiza tus datos")]),_c('b-form',{on:{"submit":_vm.onSubmit}},[_c('b-form-group',{attrs:{"id":"profile-input-group-name","label":"Nombre(s):","label-for":"input-name"}},[_c('b-form-input',{class:{ 
                    'is-invalid': _vm.$v.form.name.$dirty && _vm.$v.form.name.$error,
                    'is-valid': _vm.$v.form.name.$dirty && !_vm.$v.form.name.$error, 
                    },attrs:{"id":"profile-input-name","type":"text","placeholder":"Tu nombre completo"},on:{"input":_vm.$v.form.name.$touch},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),(_vm.$v.form.name.$error)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Debe ingresar su(s) nombre(s) ")]):_vm._e()],1),_c('b-form-group',{attrs:{"id":"profile-input-group-email","label":"Correo electrónico:","label-for":"profile-input-email"}},[_c('b-form-input',{class:{ 
                        'is-invalid': _vm.$v.form.email.$dirty && _vm.$v.form.email.$error,
                        'is-valid': _vm.$v.form.email.$dirty && !_vm.$v.form.email.$error, 
                    },attrs:{"id":"profile-input-email","type":"email","placeholder":"Tu email","autocomplete":"username"},on:{"input":_vm.$v.form.email.$touch},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}}),(_vm.$v.form.email.$error)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" No ha ingresado un email válido. ")]):_vm._e()],1),_c('b-form-group',{attrs:{"id":"profile-input-group-phone","label":"Teléfono:","label-for":"profile-input-phone"}},[_c('b-form-input',{class:{ 
                    'is-invalid': _vm.$v.form.phone.$dirty && _vm.$v.form.phone.$error,
                    'is-valid': _vm.$v.form.phone.$dirty && !_vm.$v.form.phone.$error, 
                    },attrs:{"id":"profile-input-phone","type":"text","placeholder":"Teléfono a 10 dígitos"},on:{"input":_vm.$v.form.phone.$touch},model:{value:(_vm.form.phone),callback:function ($$v) {_vm.$set(_vm.form, "phone", $$v)},expression:"form.phone"}}),(_vm.$v.form.phone.$error)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Debe ingresar su teléfono ")]):_vm._e()],1),_c('b-form-group',{attrs:{"id":"profile-input-group-password","label":"Contraseña:","label-for":"input-password"}},[_c('b-form-input',{class:{ 
                    'is-invalid': _vm.$v.form.password.$dirty && _vm.$v.form.password.$error,
                    'is-valid': _vm.$v.form.password.$dirty && !_vm.$v.form.password.$error, 
                    },attrs:{"id":"profile-input-password","type":"password","placeholder":"Tu contraseña","autocomplete":"off"},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}}),(_vm.$v.form.password.$error)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" La contraseña debe ser de al menos seis caracteres. ")]):_vm._e()],1),_c('b-form-group',{attrs:{"id":"profile-input-group-password_confirm","label":"Confirmar contraseña:","label-for":"input-password_confirm"}},[_c('b-form-input',{class:{ 
                    'is-invalid': _vm.$v.form.password_confirm.$dirty && _vm.$v.form.password_confirm.$error,
                    'is-valid': _vm.$v.form.password_confirm.$dirty && !_vm.$v.form.password_confirm.$error, 
                    },attrs:{"id":"profile-input-password-confirm","type":"password","placeholder":"Repite tu contraseña","autocomplete":"off"},model:{value:(_vm.form.password_confirm),callback:function ($$v) {_vm.$set(_vm.form, "password_confirm", $$v)},expression:"form.password_confirm"}}),(_vm.$v.form.password_confirm.$error)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Debe repetir exactamente la contraseña. ")]):_vm._e()],1),_c('div',{staticClass:"d-flex w-100 justify-content-between"},[_c('b-button',{attrs:{"type":"submit","size":"sm","variant":"primary"}},[_vm._v("Guardar")])],1),(_vm.updateProfileError != null && _vm.updateProfileError == true)?_c('div',{staticClass:"alert alert-danger mt-3",attrs:{"role":"alert"}},[_vm._v(" "+_vm._s(_vm.updateProfileErrorMessage)+" ")]):_vm._e(),(_vm.updateProfileComplete == true)?_c('div',{staticClass:"alert alert-success mt-3",attrs:{"role":"alert"}},[_vm._v(" Se ha enviado su solicitud correctamente. Te contactaremos lo antes posible. ")]):_vm._e()],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[(_vm.identity != null)?_c('MyAssistance',{attrs:{"model":_vm.identity.profile.admin}}):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }