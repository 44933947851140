































































































































































































import { Component, Vue } from 'vue-property-decorator';
import Parallax from '@/components/Parallax.vue';
import VerticalAligned from '@/components/VerticalAligned.vue';
import ContactService from '@/services/contact.service';
import { GenericResponse } from '@/services/interceptor';
import { MetaInfo } from 'vue-meta';
import { Validate, Validations } from 'vuelidate-property-decorators';
import { required, email, minLength, sameAs } from 'vuelidate/lib/validators'

@Component({
  metaInfo(this: Contact):MetaInfo {
    return {
      title: this.getPageTitle(),
      meta: [
        { name: 'description', content: this.getPageDescription() }
      ]
    }
  },
  components: {
    Parallax,
    VerticalAligned
  },
})
export default class Contact extends Vue {
    getPageTitle() {
      return `${this.$data.title} | Pacific Standard`;
    }

    getPageDescription() {
      return this.$data.description;
    }

    data() {
        return {
          title: 'Últimas noticias',
          description: 'Consulta las últimas noticias de nuestra empresa.',
          
          form: {
            'name': '',
            'email': '',
            'phone': '',
            'location': '',
            'body': ''
          },
          sent: false,
          success: null
        };
    }


    @Validations()
    validations() {
      return {
          form: {
            name: { required },
            email: { email, required },
            phone: { required },
            location: { required },
            body: { required }
          }
      };
    }


    onSubmit(event) {
      event.preventDefault()

      this!['$v'].$touch()
      if (!this!['$v'].$invalid) {
        this.$data.sent = true;
        ContactService.sendContactRequest(this.$data.form).then((response:GenericResponse) => {
          this.$data.sent = true;
          this.$data.success = response.success;
          if(response.success) {
            this.reset();
            this!['$v'].$reset()
          }
        });
      } else {
        console.log('error')
      }
    }
    
    reset() {
      this.$data.form.name = '';
      this.$data.form.email = '';
      this.$data.form.phone = '';
      this.$data.form.location = '';
      this.$data.form.body = '';
    }

    getAssetPath(image:string):string {
        let images = require.context('../assets/', true, /\.jpg$/);
        return images(image);
    }
}
