import axios from "axios";

export interface Brand {
    id:          number;
    status:      number;
    featured:    boolean;
    name:        string;
    logo:        string;
    background:  string;
    parallax:    string;
    description: string;
    slug:        string;
}

export interface Category {
    id:          number;
    status:      number;
    featured:    boolean;
    name:        string;
    background:  string;
    parallax:    string;
    description: string;
    slug:        string;
}

export interface Accesory {
    title:    string;
    subtitle: string;
}

export interface Attribute {
    attribute: string;
    value:     string;
}

export interface Picture {
    thumb_picture: string;
    picture:       string;
    name:          null | string;
}

export interface Product {
    id:                    number;
    status:                number;
    featured:              boolean;
    category:              Brand;
    brand:                 Brand;
    sku:                   string;
    name:                  string;
    description:           string;
    features:              string;
    features_background:   string;
    accesories_background: string;
    video:                 string;
    tags:                  string[];
    attributes:            Attribute[];
    accesories:            Accesory[];
    picture:               Picture|null;
    pictures:              Picture[];
    slug:                  string;
    datasheet:             string|null;
}

export interface Distributor {
    id:          number;
    status:      number;
    name:        string;
    address:     string;
    phone:       string;
    lat:         number;
    lng:         number;
    zipcodes:    string[];
}

export interface ProductQuery {
    brand_id:       string|null;
    category_id:    string|null;
    search:         string|null;
    page:           string|null;
}

class CatalogService {
    getBrands(params:any={}):Promise<Brand[]> {
        const config = {
            params: params
        }

        return axios.get(`/v1/brand`, config);
    }

    getFeaturedBrands():Promise<Brand[]> {
        return this.getBrands({
            featured: 10
        });
    }

    getCategories(params:any={}):Promise<Category[]> {
        const config = {
            params: params
        }

        return axios.get(`/v1/category`, config);
    }

    getFeaturedCategories():Promise<Category[]> {
        return this.getCategories({
            featured: 10
        });
    }

    getProducts(params:any={}):Promise<Product[]> {

        const config = {
            params: params
        }

        return axios.get(`/v1/product`, config);
    }

    getFeaturedProducts():Promise<Product[]> {
        return this.getProducts({
            featured: 10
        });
    }

    getProductsByTags(tags:string[] = [], not_id:number|null):Promise<Product[]> {
        return this.getProducts({
            tags,
            not_id
        });
    }

    getProduct(id:number):Promise<Product> {
        return axios.get(`/v1/product/view?id=${id}`);
    }

    getDistributors():Promise<Distributor[]> {
        return axios.get(`/v1/distributor`);
    }
}

export default new CatalogService();