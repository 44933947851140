






import { Component, Prop, Vue } from 'vue-property-decorator';
import { vueVimeoPlayer } from 'vue-vimeo-player'
import BannerService, { Banner } from '@/services/banner.service';

@Component({
  components: {  'vimeo-player': vueVimeoPlayer }
})
export default class Video extends Vue {
    data() {
        return {
          videoID: null,
          options: {
            controls: false,
            responsive: true,
            autoplay: true,
            background: true,
            autopause: false,
            muted: false,
            loop: true
          }
        }
    }

    onReady() {
      // console.log(this.$refs.player!['play']());
		}

    created() {
      BannerService.getOne().then((model:Banner) => {
        this.$data.videoID = `${model.video_id}`;
      }).catch(error => {
        // this.$data.models = [];
      });
    }

}
