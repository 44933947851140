import { render, staticRenderFns } from "./MyQuotes.vue?vue&type=template&id=028e564b&scoped=true&"
import script from "./MyQuotes.vue?vue&type=script&lang=ts&"
export * from "./MyQuotes.vue?vue&type=script&lang=ts&"
import style0 from "./MyQuotes.vue?vue&type=style&index=0&id=028e564b&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "028e564b",
  null
  
)

export default component.exports