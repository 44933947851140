











































import { Component, Vue } from 'vue-property-decorator';
import BasketService, { Basket } from '@/services/basket.service';
import eventBus from '@/services/eventBus';
import { GenericResponse } from '@/services/interceptor';

@Component({
  components: {}
})
export default class BasketItems extends Vue {
    data() {
        return {
          model: null,
          show: false
        };
    }

    created() {
      this.$data.model = BasketService.getBasket();
      eventBus.$on('basket-updated', (basket:Basket) => {
        this.$data.model = basket;
        this.$data.show = true
      });

      eventBus.$on('toggle-basket-sidebar', () => {
        this.$data.show = !this.$data.show;
      });
    }

    deleteItem(i:number) {
      BasketService.removeItem(i);
    }

    // Clear user basket
    clear() {
        BasketService.clear();
        this.$data.showBasketSidebar = false;
    }

    // Confirm user basket
    confirm() {
      const data = { ...this.$data.model };
        BasketService.confirm(data).then((response:GenericResponse) => {
          this.clear();
          eventBus.$emit('user-baskets-updated');
          this.$router.push('/mis-cotizaciones');
        });
        this.$data.showBasketSidebar = false;
    }
}
