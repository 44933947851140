







































import { Component, Watch, Vue } from 'vue-property-decorator';
import Parallax, { ParallaxBackground } from '@/components/Parallax.vue';
import { AppliedFilters } from '@/components/products/Filters.vue';
import Breadcrumb from '@/components/Breadcrumb.vue';
import CatalogService, { Brand } from '@/services/catalog.service';
import eventBus from '@/services/eventBus';
import { MetaInfo } from 'vue-meta';

@Component({
  metaInfo(this: Products):MetaInfo {
    return {
      title: this.getPageTitle(),
      meta: [
        { name: 'description', content: this.getPageDescription() }
      ]
    }
  },
  components: {
    Parallax,
    Breadcrumb,
  },
})
export default class Products extends Vue {
    getPageTitle() {
      return `${this.$data.title} | Pacific Standard`;
    }

    getPageDescription() {
      return this.$data.description;
    }

    data() {
        return {
          title: 'Nuestras marcas',
          description: 'Pacific Standard es una marca especializada en equipo para soldar, contamos con una amplia variedad de productos para que encuentres todo lo que necesitas.',

          breadcrumbPath: [
            {
              text: 'Inicio',
              href: '/'
            },
            {
              text: 'Nuestras marcas',
              active: true
            }
          ],
          models: [],
          items: [],
          parallax: {
            background:               this.getAssetPath('./dummy/products/banner.jpg'),
            backgroundSize:           'contain',
            backgroundMobile:         this.getAssetPath('./dummy/products/banner-mobile.jpg'),
            backgroundMobileSize:     '200%'
          } as ParallaxBackground
        };
    }

    created() {
      eventBus.$on('filters-updated', (filters:AppliedFilters) => { this.$data.filters = filters; });
      this.$data.page = this.$route.params?.page != undefined ? Number.parseInt(this.$route.params?.page) : 1;
      this.loadModels(this.$data.page);
    }


    loadModels(page = 1):any {

      CatalogService.getBrands().then((models:Brand[]) => {
        this.$data.models = models;
      }).catch(error => {
        // this.$data.models = [];
      });
    }

    getAssetPath(image:string):string {
        let images = require.context('../assets/', true, /\.jpg$/);
        return images(image);
    }
}
