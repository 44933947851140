






























import { Component, Vue } from 'vue-property-decorator';
import Video from '@/components/home/Video.vue'; // @ is an alias to /src
import FeaturedProducts from '@/components/home/FeaturedProducts.vue';
import Brands from '@/components/home/Brands.vue';
import Categories from '@/components/home/Categories.vue';
import Parallax from '@/components/Parallax.vue';
import LastestNews from '@/components/home/LastestNews.vue';
import OurProducts from '@/components/home/OurProducts.vue';
import CatalogService, { Brand, Category, Product } from '@/services/catalog.service';
import { MetaInfo } from 'vue-meta';

@Component({
  metaInfo(this: Home):MetaInfo {
    return {
      title: this.getPageTitle(),
      meta: [
        { name: 'description', content: this.getPageDescription() },
        { name: 'keywords', content: this.getPageKeywords() },
      ]
    }
  },
  components: {
    Video,
    FeaturedProducts,
    Brands,
    OurProducts,
    Categories,
    Parallax,
    LastestNews,
  },
  
})
export default class Home extends Vue {
  getPageTitle() {
    return `${this.$data.title} | Pacific Standard`;
  }

  getPageDescription() {
    return this.$data.description;
  }

  getPageKeywords() {
    return this.$data.keywords;
  }

  data() {
    return {
      title: 'Equipo para soldar, máquinas inversoras soldadoras y corte de plasma',
      description: 'Pacific Standard una empresa mexicana enfocada en brindar productos de soldadura de arco, equipo de oxicorte  y soldadoras inversoras de gran calidad, que sobrepasan las expectativas de nuestros clientes y distribuidores.',
      keywords: 'soldadura, arco de soldadura, soldadora mig, equipo de seguridad, inversoras, automatizacion, electrodo revestido, mig, tig, aluminio, tobera, careta de soldador, guantes, lentes',
      
      brands: [],
      products: [],
    };
  }

  created() {

    CatalogService.getFeaturedProducts().then((models:Product[]) => {
      this.$data.products = models;
    }).catch(error => {
      this.$data.products = [];
    });

    CatalogService.getFeaturedBrands().then((models:Brand[]) => {
      this.$data.brands = models;
    }).catch(error => {
      this.$data.brands = [];
    });
  }

  getAssetPath(image:string):string {
      let images = require.context('../assets/', true, /\.jpg$/);
      return images(image);
  }
}
