import axios from "axios";

export interface Post {
    id:            number;
    created_at:    number;
    updated_at:    number;
    status:        number;
    title:         string;
    extract:       string;
    thumb_picture: string;
    picture:       string;
    content:       string;
    slug:          string;
}

export interface BlogQuery {
    page:           string|null;
}


class BlogService {
    getPosts(params:any={}):Promise<Post[]> {
        
        const config = {
            params: params
        }

        return axios.get(`/v1/post`, config);
    }

    getPost(id:number):Promise<Post> {
        return axios.get(`/v1/post/view?id=${id}`);
    }
}

export default new BlogService();