
































import { Component, Watch, Vue } from 'vue-property-decorator';
import Parallax from '@/components/Parallax.vue';
import ProductItem from '@/components/products/ProductItem.vue';
import Breadcrumb from '@/components/Breadcrumb.vue';
import PostItem from '@/components/blog/PostItem.vue';
import BlogService, { Post } from '@/services/blog.service';
import { MetaInfo } from 'vue-meta';

@Component({
  metaInfo(this: PostDetail):MetaInfo {
    return {
      title: this.getPageTitle(),
      meta: [
        { name: 'description', content: this.getPageDescription() }
      ]
    }
  },
  components: {
    Parallax,
    Breadcrumb,
    ProductItem,
    PostItem
  },
})
export default class PostDetail extends Vue {
    getPageTitle() {
      return `${this.$data.title} | Pacific Standard`;
    }

    getPageDescription() {
      return this.$data.description;
    }

    data() {
        return {
          title: 'Últimas noticias',
          description: 'Consulta las últimas noticias de nuestra empresa.',

          breadcrumbPath: [
            {
              text: 'Inicio',
              href: '/'
            },
            {
              text: 'Blog',
              active: '/blog'
            },
            {
              text: 'FFACSA inaugura nuevo cencepto',
              active: true
            }
          ],
          model: null
        };
    }

    @Watch('$route.params', { deep: true })
    onModelChange(newVal:any):void {
        this.updateModel();
    }

    created() {
        this.updateModel();
    }

    updateModel() {
      let id = parseInt(this.$route.params?.id);
      BlogService.getPost(id).then((model:Post) => {
        this.$data.model = model;
        this.$data.breadcrumbPath = [
            {
                text: 'Inicio',
                href: '/'
            },
            {
                text: 'Blog',
                href: '/blog'
            },
            {
                text: model.title,
                active: true
            }
        ];

        this.$data.title = model.title;
        this.$data.description = model.extract;
      }, error => {
        // console.log('error', error);
      });
    }
}
